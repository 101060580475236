<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/admin">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Usuários</li>
              </ol>
            </nav>

            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Usuários
                </h3>
              </div>
              <div class="col text-right">
                <router-link to="/admin/adicionar-usuario"  tag="primary">
                  <base-button type="primary" id="btn_new_product">Adicionar Usuário</base-button>
                </router-link>-
              </div>
            </div>


            <div class="table-responsive">
              <usuarios-card-loading v-if="state.isLoading"/>
              <table class="table tablesorter table align-items-center table-flush" v-if="!state.isLoading">
                <thead class="thead-light">
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Status</th>
                    <th></th>
                </thead>
                <tbody class="list">
                  <p v-if="state.hasError">
                    Aconteceu um erro ao carregar os usuários
                  </p>
                  <p v-if="!state.usuarios.length && !state.isLoading">
                    Nenhum usuário cadastrado
                  </p>
                  <usuarios-card
                    @deletarUsuario="deletarUsuario"
                    v-else
                    v-for="usuario in state.usuarios"
                    :key="usuario.id"
                    :usuario="usuario"
                    />
                </tbody>
              </table>      
            </div>
          <div class="card-footer d-flex justify-content-end">
          </div>
      </stats-card>
        </div>
        
      </div>
    </div>
  </div>

</template>
<script>
  import { onMounted, reactive, onErrorCaptured  } from 'vue'
  import UsuariosCard from '../components/CorpoTabelaUsuario'
  import UsuariosCardLoading from '../components/CorpoTabelaUsuario/Loading'
  import services from '../services'

  export default {
    components: {
      UsuariosCard,
      UsuariosCardLoading
    },
    setup () {
      const state = reactive({
        isLoading: false,
        usuarios: [],
        hasError: false
      })
      onErrorCaptured(handleErrors)

      onMounted(() => {
        fetchUsuarios()
      })

      function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
      }

      async function deletarUsuario(id){
        if(!window.confirm("Deseja apagar este Usuario?")){
          return; 
        }
        try{
          state.isLoading = true;
          var erro_deletar = await services.usuario.delete({
            id
          })
          console.log(erro_deletar);  
          fetchUsuarios();

          }catch (error) {
          handleErrors(error)
        }
        fetchUsuarios();
      }

      async function fetchUsuarios () {
        try {
          state.isLoading = true
          const { data } = await services.usuario.getAll({
            type: state.currentClientType
          }
          )
          console.log(data)
          state.usuarios = data
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }
      return {
        state,
        deletarUsuario
      }
    }
  }
</script>

  <style>
    #btn_new_product{
      margin-bottom: 10px;
    }
  </style>