<template>
<modal-factory />
  <div>
  
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
    
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/admin">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/admin/produtos"  tag="primary">Produtos</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>

          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Novo Produto</h2>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Produto
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Código Site</label>
                          <input type="text" class="form-control" v-model="state.codigo.value" readonly/>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Código</label>
                          <input type="text" class="form-control" v-model="state.codigo_manual.value" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Status</label>
                          <select class="form-control" v-model="state.status">
                            <option value="ATIVO">Ativo</option>
                            <option value="INATIVO">Inativo</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Destaque</label>
                          <select class="form-control" v-model="state.destaque">
                            <option value="SIM">Sim</option>
                            <option value="NAO">Não</option>
                          </select>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Categoria</label>
                          
                          <select class="form-control" v-model="state.categoria.value" >
                            <select-categorias
                              :valor="state.categoria.value"
                              v-for="categoria in state.categorias"
                              :key="categoria.id"
                              :categoria="categoria"
                            />
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Preço Normal</label>
                          <input type="text" class="form-control" v-model="state.preco.value" />
                          <span v-if="!!state.preco.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.preco.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Preço Desconto</label>
                          <input type="text" class="form-control" v-model="state.preco_promocional.value" />
                          <span v-if="!!state.preco_promocional.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.preco_promocional.errorMessage }}
                          </span>  
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome </label>
                          <input type="text" class="form-control" v-model="state.nome.value" />
                          <span v-if="!!state.nome.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nome.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome na URL</label>
                          <input type="text" class="form-control" v-model="state.nome_url.value" />
                          <span v-if="!!state.nome_url.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nome_url.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>                      
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Descrição</label>
                          <textarea v-model="state.descricao.value" class="form-control"></textarea>
                          <span v-if="!!state.descricao.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.descricao.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Imagens do Produto
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                <div class="row">
                  <div class="col-sm-12">
                      <div class="quadro_topo">
                        Adicionar Imagens dos Produtos
                      </div>
                      <div class="quadro_corpo">
                        <div class="row">
                          <div class="col-lg-3 col-md-4">
                            <div class="inputFile" style="margin-top: 10px; height: 33px !important" id="">
                                <div style="position: absolute; left: 50%; margin-left: -60px; margin-top: 7px">
                                    Selecionar Arquivo
                                </div>
                                <div style="border: #000 solid 1px">
                                    <input type="file" style="width: 100%" @change="arquivosDocumentos" multiple>
                                </div>
                            </div> 
                          </div>
                          <div class="col-lg-8 col-offset-lg-1 col-md-8">
                            <div v-for="documento in state.filesImagem" :key="documento">
                              <img :src="documento.url" class="imagem">
                            </div>
                          </div>
                        </div> 
                      </div>     
                  </div>
              </div>
                </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary my-4" id="submit-button" type="submit">Save</button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancel</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>

</template>

<script>
import { reactive, onMounted } from 'vue'
import services from '../services'
import { useField } from "vee-validate";
import { validateEmptyAndLength3 } from '../utils/validators'
import useModal from '../hooks/useModal'
import ModalFactory from '../components/ModalFactory'
import { useRouter } from 'vue-router'
import SelectCategorias from '../components/CategoriaSelect.vue';

export default {
  
  components: { ModalFactory },
  setup (_, { emit }) {
    const router = useRouter()
    var id_produto = router.currentRoute._value.params.id
    if(id_produto === undefined){
      id_produto = 0;
    }
    const modal = useModal()

    function handleCategory(){
      modal.open({
        component: 'ModalCategory'
      })
    }

    function cancel(){
      router.push({ name: 'produtos' })
    }
    const {
      value: codigoValue,
      errorMessage: codigoErrorMessage
    } = useField('codigo', validateEmptyAndLength3)

    const {
      value: codigoManualValue,
      errorMessage: codigoManualErrorMessage
    } = useField('codigo_manual', validateEmptyAndLength3)

    const {
      value: statusValue,
      errorMessage: statusErrorMessage
    } = useField('status', validateEmptyAndLength3)

    const {
      value: destaqueValue,
      errorMessage: destaqueErrorMessage
    } = useField('destaque', validateEmptyAndLength3)

    const {
      value: precoValue,
      errorMessage: precoErrorMessage
    } = useField('preco', validateEmptyAndLength3)

    const {
      value: precoPromocionalValue,
      errorMessage: precoPromocionalErrorMessage
    } = useField('preco_promocional', validateEmptyAndLength3)

    const {
      value: categoriaValue,
      errorMessage: categoriaErrorMessage
    } = useField('categoria', validateEmptyAndLength3)    

    const {
      value: nomeValue,
      errorMessage: nomeErrorMessage
    } = useField('nome', validateEmptyAndLength3)

    const {
      value: nomeURLValue,
      errorMessage: nomeURLErrorMessage
    } = useField('nome_url', validateEmptyAndLength3)    

    const {
      value: descricaoValue,
      errorMessage: descricaoErrorMessage
    } = useField('descricao', validateEmptyAndLength3)

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      categorias:[],
      filesImagem:[],
      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage
      },

      codigo_manual: {
        value: codigoManualValue,
        errorMessage: codigoManualErrorMessage
      },

      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage
      },
      
      nome_url: {
        value: nomeURLValue,
        errorMessage: nomeURLErrorMessage
      },

      descricao: {
        value: descricaoValue,
        errorMessage: descricaoErrorMessage
      },
      categoria: {
        value: categoriaValue,
        errorMessage: categoriaErrorMessage
      },

      preco: {
        value: precoValue,
        errorMessage: precoErrorMessage
      },

      preco_promocional: {
        value: precoPromocionalValue,
        errorMessage: precoPromocionalErrorMessage
      },

      status: {
        value: statusValue,
        errorMessage: statusErrorMessage
      },
      destaque: {
        value: destaqueValue,
        errorMessage: destaqueErrorMessage
      }
    }) 
    onMounted(() => {
      fetchProduto()
      fetchCategorias()
    })

    function arquivosDocumentos(event){
      
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objDocumento = new Object;
        objDocumento.id = index +1;
        objDocumento.file = element
        objDocumento.url =  URL.createObjectURL(element)
        state.filesImagem.push(objDocumento);
      }

    }

    async function fetchCategorias () {
      try {
        state.isLoading = true
        const { data } = await services.categoria.getAll({
        })
        state.categorias = data;
      } catch (error) {
        handleErrors(error)
      }
    }

    async function fetchProduto () {
      if(id_produto != 0){
        try {
          state.isLoading = true
          const { data } = await services.produto.getById({
            id:id_produto
          })
          state.codigo.value = data.id
          state.codigo_manual.value = data.codigo
          state.nome.value = data.nome
          state.nome_url.value = data.nome_url
          state.descricao.value = data.descricao
          state.preco.value = data.preco
          state.preco_promocional.value = data.preco_promocional
          state.status = data.status
          state.destaque = data.destaque
          state.categoria.value = data.categoria_id
/*
          var imagens = await services.produto.getImagens({
            produto_id:id_produto
          })

          for (let index = 0; index < imagens.length; index++) {
          //  var objDocumento = new Object;
          //  objDocumento.file = element
          //  objDocumento.url =  URL.createObjectURL(element)
          //  state.filesImagem.push(objDocumento);
          }
*/

          
        } catch (error) {
          handleErrors(error)
        }
      }
    }

    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }

    async function handleSubmit() {
      try {
        state.isLoading = true
        var retorno;
        if(state.codigo.value){
          retorno = await services.produto.update({
            id: state.codigo.value,
            codigo: state.codigo_manual.value,
            nome: state.nome.value,
            nome_url: state.nome_url.value,
            descricao: state.descricao.value,
            preco: state.preco.value,
            preco_promocional: state.preco_promocional.value,
            status: state.status,
            destaque: state.destaque,
            categoria_id: state.categoria.value
          })
          if (!retorno) {
            router.push({ name: 'produtos' })
            state.isLoading = false
            return
          }
        }else{
          console.log('sem codigo');
          retorno = await services.produto.save({
            codigo: state.codigo_manual.value,
            nome: state.nome.value,
            nome_url: state.nome_url.value,
            descricao: state.descricao.value,
            preco: state.preco.value,
            preco_promocional: state.preco_promocional.value,
            status: state.status,
            destaque: state.destaque,
            categoria_id: state.categoria.value
          })
          if (retorno.erro == true) {
            console.log('acusa erro')
            return
          }else{
            state.codigo.value = retorno.data.id
          }
        }
        console.log(state.codigo.value);


        for (let i = 0; i < state.filesImagem.length; i++) {
          var formImagens = new FormData();
          formImagens.append("produto_id",state.codigo.value);
          formImagens.append("imagem",state.filesImagem[i].file);
          var retorno_imagens = await services.produto.save_imagens(formImagens)
        }
        console.log(retorno_imagens);
      router.push({ name: 'produtos' })
//      state.isLoading = false
//      console.log(retorno_imagens);



//        if (errors.status === 400) {
        //  toast.error('Ocorreu um erro ao criar a conta')
  //      }
        state.isLoading = false

      } catch (error) {
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
  return {
    state,
    SelectCategorias,
    handleCategory,
    handleSubmit,
    arquivosDocumentos,
    emit,
    cancel
  }
  }
}





</script>

<style scoped>
  textarea {
    resize: none;
  }
      .inputFile {
			width: 100%;
			height:60px;
			position: relative;
			overflow: hidden;
      border-bottom: #000 solid 1px;
		}

    .inputFile input {
            opacity: 0;
			filter: alpha(opacity=0);
			right: 0;
            padding-top: 5px;
			z-index: 2;
            height: 80px;
			font-size: 100px; /* Aumenta tamanho do campo */
		}
    .imagem{
      float:left;
      margin-right: 15px;
      width: 80px;
  }
</style>