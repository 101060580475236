<template>
    <tr @click="handleToggle">
        <td>
            {{ categoria.id }}
        </td>
        <th>{{ categoria.nome}}</th>
        <th>{{ categoria.status}}</th>

        <th class="text-right">
            <router-link :to="{ name: 'AlterarCategoria', params: { id: categoria.id } }">
                <button class="btn btn-primary" >Ver / Alterar</button>
            </router-link>
            <button class="btn btn-danger" @click="$emit('deletarCategoria',categoria.id)">Deletar</button>
        </th>
    </tr>
</template>

<script>
import { reactive } from 'vue'

export default {
    props: {
        isOpened: { type: Boolean, default: false },
        categoria: { type: Object, required: true }
    },
    setup(props) {

      const state = reactive({
        isOpen: props.isOpened,
        isClosing: !props.isOpened
      })

      async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        state,
        handleToggle    
      }
  }  
}
</script>
