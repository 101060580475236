<template>
  <li @click="$emit('selecionarCategoria',categoria.id)">{{categoria.nome}}</li>

</template>
<script>
import { reactive } from 'vue'

export default {
    props: {
      isOpened: { type: Boolean, default: false },
      categoria: { type: Object, required: true }
    },
    setup(props) {
      const state = reactive({
        isOpen: props.isOpened,
        isClosing: !props.isOpened
      })
      async function handleToggle () {
      state.isClosing = true
      //  await wait(250)
      state.isOpen = !state.isOpen
      state.isClosing = false  
      }
      return {
        state,
        handleToggle    
      }
    },
}
</script>
<style scoped>
  li{
    font-size: 1.5rem;
    font-weight:bold;
    padding: 1rem;
    width:100%;
    list-style-type: none;
    border-bottom: #DEDEDE solid 1px;
    padding-left: 3rem;
    text-transform: uppercase;
    color:#7F8280;
}
main{
  background-color: #F9F9F9;
}
  li:hover{
    cursor: pointer;
  }
</style>