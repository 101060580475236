<template>
  <main>
    <topo />
        <div class="container">
            <div class="row row_fale_conosco">
                <div class="col-md-10 offset-md-1">

                    <div class="fale_conosco">
                        <div class="user">
                            <img src="@/assets/img/user.png" />
                        </div>
                        <h1 v-if="state.sucesso" style="text-align:center; font-size: 3rem">MENSAGEM ENVIADA COM SUCESSO</h1>  
                        <loading  v-if="state.isLoading" />
                        <div v-if="!state.isLoading">
                          <p>
                          Preencha todos os campos e clique
                          no botão para entrar em contato com um dos
                          nossos colaboradores</p>

                          <form @submit.prevent="handleSubmit">  
                              <div class="form-group">
                                  <label for="nome">Nome Completo</label>
                                  <input type="text" class="form-control" v-model="state.nome.value" id="nome">
                              </div>
                              <div class="row">
                                  <div class="col-sm-3">
                                      <div class="form-group">
                                          <label for="ddd">DDD</label>
                                          <input type="text" class="form-control" v-model="state.ddd.value" id="ddd">
                                      </div>
                                  </div>
                                  <div class="col-sm-9">
                                      <div class="form-group">
                                          <label for="telefone">Telefone</label>
                                          <input type="text" class="form-control" v-model="state.telefone.value" id="telefone">
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label for="email">E-mail</label>
                                  <input type="text" class="form-control" v-model="state.email.value" id="email">
                              </div>
                              <div class="form-group">
                                  <label for="mensagem">Mensagem</label>
                                  <textarea class="form-control" rows="6" v-model="state.mensagem.value" id="mensagem"></textarea>
                              </div>

                              <div>
                                <button type="submit">Enviar Mensagem</button>
                              </div>
                          </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <bottom />
  </main>
</template>

<script>
import { reactive } from 'vue'
import Topo from '../../layout/Site/TopoAlternativo.vue'
import Bottom from '../../layout/Site/Bottom.vue'
import { useField } from "vee-validate";
import { validateEmptyAndLength3 } from '../../utils/validators'
import services from '../../services'
import Loading from '../../components/Loading'

export default({
    setup() {
      const {
        value: nomeValue,
        errorMessage: nomeErrorMessage
      } = useField('nome', validateEmptyAndLength3)

      const {
        value: dddValue,
        errorMessage: dddErrorMessage
      } = useField('ddd', validateEmptyAndLength3)

      const {
        value: telefoneValue,
        errorMessage: telefoneErrorMessage
      } = useField('telefone', validateEmptyAndLength3)

      const {
        value: emailValue,
        errorMessage: emailErrorMessage
      } = useField('email', validateEmptyAndLength3)

      const {
        value: mensagemValue,
        errorMessage: mensagemErrorMessage
      } = useField('mensagem', validateEmptyAndLength3)

      const state = reactive({
        hasErrors: false,
        isLoading: false,
        sucesso: false,
        nome: {
          value: nomeValue,
          errorMessage: nomeErrorMessage
        },
        ddd: {
          value: dddValue,
          errorMessage: dddErrorMessage
        },
        telefone: {
          value: telefoneValue,
          errorMessage: telefoneErrorMessage
        },
        email: {
          value: emailValue,
          errorMessage: emailErrorMessage
        },
        mensagem: {
          value: mensagemValue,
          errorMessage: mensagemErrorMessage
        }
    })    

    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }

    async function handleSubmit() {
      try {
        state.isLoading = true

          const { errors }= await services.FaleConosco.save({
            nome: state.nome.value,
            ddd: state.ddd.value,
            telefone: state.telefone.value,
            email: state.email.value,
            mensagem: state.mensagem.value
        })
        if (!errors) {
          state.isLoading = false
          state.sucesso = true
          state.nome.value = ""
          state.ddd.value = ""
          state.telefone.value = ""
          state.email.value = ""
          state.mensagem.value = ""
          return
        }

        state.isLoading = false
      } catch (error) {
        handleErrors(error)
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
      
      return {
        Topo,
        Bottom,
        state,
        handleSubmit,
        Loading
      }
        
        
    }
})
</script>

<style scoped>
  img{
    width: 100%;
  }
  p{
    margin-top: 3rem;
    font-size:  2rem;
    font-weight: bold;
    line-height: 1.2;
  }
  .fale_conosco{
    border: #DEDEDE solid 2px;
    padding: 4rem;
    border-radius: 3rem 3rem 1rem 1rem;
        background-color: #fff;

  }
  .row_fale_conosco{
    margin-top:5rem;
    margin-bottom:5rem;
  }
  .user{
    padding: 1rem;
    margin-top: -7rem;
    width: 6rem;
    height: 6rem;
    background-color: #00A859;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    border-radius: 1rem 1rem 1rem 1rem;
  }
  label{
    font-size: 1.5rem;
    font-weight: bold;
  }
  input[type="text"] {
    font-size: 1.2rem;
  }
  textarea {
    font-size: 1.2rem;
    resize: none;
  }
  button{
    width: 100%;
    background-color: #00A859;
    padding: 1rem;
    font-size: 1.5rem;
    color: #fff;
    border: none;
    font-weight: bold;
    border-radius: 1rem;
  }
</style>