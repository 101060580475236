import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Produtos from "../views/Produtos.vue";
import AdicionarProduto from "../views/AdicionarProduto.vue";

import Categorias from "../views/Categorias.vue";
import AdicionarCategoria from "../views/AdicionarCategoria.vue";

import Usuarios from "../views/Usuarios.vue";
import AdicionarUsuario from "../views/AdicionarUsuario.vue";

import FaleConosco from "../views/FaleConosco.vue";
import AdicionarFaleConosco from "../views/AdicionarFaleConosco.vue";


import Login from "../views/Login.vue";
import Guard from "../services/middleware.js"


//Site
import HomeSite from "../views/Site/Home.vue"; 
import ProdutosSite from "../views/Site/Produtos.vue"; 
import ProdutoSite from "../views/Site/Produto.vue"; 
import Sobre from "../views/Site/Sobre.vue"; 
import FaleConoscoSite from "../views/Site/FaleConosco"; 

const routes = [
  {
    path: "/",
    name: "HomeSite",
    components: { default: HomeSite },
  },
  {
    path: "/produtos",
    name: "Produtos",
    components: { default: ProdutosSite },
  },
  {
    path: "/produto/:nome_url/:id",
    name: "Produto",
    components: { default: ProdutoSite }
  },
  {
    path: "/quem-somos",
    name: "QuemSomos",
    components: { default: Sobre }
  },  
  {
    path: "/fale-conosco",
    name: "FaleConoscoSite",
    components: { default: FaleConoscoSite }
  },    
  {
    
    path: "/admin",
    redirect: "/dashboard",
    component: DashboardLayout ,
    children: [
      {
        path: "/admin",
        name: "home",
        components: { default: Dashboard },
        beforeEnter: Guard.auth
      },

      {
        path: "/admin/produtos",
        name: "produtos",
        components: { default: Produtos },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/adicionar-produto",
        name: "AdicionarProduto",
        components: { default: AdicionarProduto },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/alterar-produto/:id",
        name: "AlterarProduto",
        components: { default: AdicionarProduto },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/categorias",
        name: "categorias",
        components: { default: Categorias },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/adicionar-categoria",
        name: "AdicionarCategoria",
        components: { default: AdicionarCategoria },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/alterar-categoria/:id",
        name: "AlterarCategoria",
        components: { default: AdicionarCategoria },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/usuarios",
        name: "usuarios",
        components: { default: Usuarios },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/adicionar-usuario",
        name: "AdicionarUsuario",
        components: { default: AdicionarUsuario },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/alterar-usuario/:id",
        name: "AlterarUsuario",
        components: { default: AdicionarUsuario },
        beforeEnter: Guard.auth
      },    
      {
        path: "/admin/fale-conosco",
        name: "FaleConosco",
        components: { default: FaleConosco },
        beforeEnter: Guard.auth
      },
      {
        path: "/admin/visualizar-fale-conosco/:id",
        name: "AlterarFaleConosco",
        components: { default: AdicionarFaleConosco },
        beforeEnter: Guard.auth
      }
    ],
  },
  {
    path: "/login",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
