<template>
  <main>
    <topo />
    <div class="container">
      <ul id="breadcrumbs">
        <li><a href="/">Página Inicial ></a></li>
        <li><a href="/produtos">Produtos ></a></li>
        <li>{{state.produto.nome}}</li>
      </ul>

      <div class="row principal">
        <div class="col-sm-5">
          <div class="row">
            <div class="col-sm-2" v-for="imagem in state.imagens" :key="imagem">
              <img :src="imagem" class="miniatura">
            </div>
            <div class="col-sm-9">
              <img :src="state.imagens[0]" class="img_principal">
            </div>
          </div>
        </div>
        <div class="col-sm-7">
          <h1>{{state.produto.nome}}</h1>
          <p class="codigo">Código: {{state.produto.codigo}}</p>
          <h2 class="preco">R$ {{state.produto.preco}}</h2>
          <a :href="'https://api.whatsapp.com/send?phone=55065984289957&text=Olá, gostaria de comprar o produto '+state.produto.nome+' com o código '+state.produto.codigo">
            <button class="btn_comprar">Comprar pelo Whatsapp</button>
          </a>
        </div>
      </div>
      <h1>Descrição</h1>
      <div class="row descricao">
        <div class="col-sm-12">
          <div class="corpo_descricao">
            {{state.produto.descricao}}
          </div>
          <div class="linha_verde">
          </div>
        </div>
      </div>

      <h1>Produtos Relacionados</h1>
      <div class="row">
       <produtos-card
        v-for="produto in state.produtos"
        :key="produto.id"
        :produto="produto"
        />
      </div>
    </div>
    <bottom />
  </main>
</template>

<script>
import Topo from '../../layout/Site/TopoAlternativo.vue'
import Bottom from '../../layout/Site/Bottom.vue'
import { useRouter } from 'vue-router'
import services from '../../services'
import { onMounted, reactive, onErrorCaptured  } from 'vue'
import ProdutosCard from '../../components/CorpoTabelaProdutoSite'


export default({
    setup() {
      const router = useRouter()
      var id_produto = router.currentRoute._value.params.id
      if(id_produto === undefined){
        id_produto = 0;
      }
    const state = reactive({
      hasErrors: false,
      isLoading: false,
      imagens:[],
      produtos:[],
      imagem_selecionada:'',
      produto:{ 
        codigo:'',
        nome:'',
        preco:'',
        descricao:'',
      }
    })

    onErrorCaptured(handleErrors)
    
    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }


    onMounted(() => {
      if(id_produto != 0){
        fetchProduto()
      }
    })

      async function fetchProdutos (categoria_id) {
        try {
          state.isLoading = true
          const { data } = await services.produto.getRelacionados({
            categoria_id : categoria_id
          }
          )
          console.log(data)
          state.produtos = data
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }

    async function fetchProduto () {
      try {
        state.isLoading = true
        const { data } = await services.produto.getById({
          id:id_produto
        }
        )
        state.produto.codigo = data.codigo
        state.produto.nome = data.nome
        state.produto.preco = data.preco
        state.produto.descricao = data.descricao
        state.produto.categoria_id = data.categoria_id      
        state.isLoading = false
        
        //Tras um array com os ids das imagens 
        state.imagens = await services.produto.getImagens({
          produto_id: id_produto
        })

        console.log(state.imagens)
        
        fetchProdutos(data.categoria_id)
       // imagens



      } catch (error) {
        handleErrors(error)
      }
    }

        return {
            Topo,
            Bottom,
            state,
            ProdutosCard
        }     
    },
})
</script>

<style scoped>
#breadcrumbs{
  margin-top: 3rem;
  margin-bottom: 5rem;
}
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
li {
    float: left;
    margin-left: 0.1rem;
    list-style-type: none;
    font-size: 1.5rem;
        color:#7F8280;
}
li a {
    color:#7F8280;
}
.principal{
  margin-bottom: 3rem;
}
.preco{
  color: #00A859;
  font-size: 5rem;
}
.btn_comprar{
  background-color: #00A859;
  color: #fff;
  border: none;
  width: 30rem;
  font-size: 2.5rem;
  font-weight: bold;
  border-radius: 0.7rem;
}
.descricao{
  margin-bottom:4rem;
}
.corpo_descricao{
  background-color: #fff;
  border: #CACACA solid 1px;
  padding:3rem;
  text-transform: uppercase;
  font-weight: 2rem;
  font-weight: bold;
}
.linha_verde{
  height: 0.3rem;
  width: 100%;
  background-color: #00A859;
}
.miniatura{
  width: 100%;
}
.img_principal{
  width: 100%;
}
.codigo{
  font-size: 1.7rem;
}
</style>