<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <h4>Páginas</h4>
                    <ul>
                        <li><a href="/">Página Inicial</a></li>
                        <li><a href="/produtos">Produtos</a></li>
                        <li><a href="/quem-somos">Quem Somos</a></li>
                        <li><a href="/fale-conosco">Fale Conosco</a></li>
                    </ul>
                </div>
                <div class="col-sm-5">
                    <h4>A Empresa</h4>
                    <ul>
                        <li>Master Distribuidora de Peças Agrícolas</li>
                        <li>Av Florianópolis Nº 3143, Primavera do leste -MT</li>
                        <li>41.999.618/0001-94</li>
                        <li>vendas@masteragricolas.com.br</li>
                    </ul>
                </div>
                <div class="col-sm-4">
                    <h4>Central de Atendimento</h4>
                    <ul>
                        <li>(65) 98428-9957</li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
</script>

<style scoped>
    footer{
        background-color: #00A859;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    h4{
        color:#fff !important;
        font-size: 2rem;
        margin-bottom: 3rem;
    }
    footer li a{
        color:#fff !important;
        font-size: 1.6rem;
    }
    ul{
        padding-left: 0;
    }
    li {
        list-style-type: none;
        color: #fff;
        font-size: 1.6rem;
         padding-left: 0;
    }
    @media (max-width: 577px) {
        footer{
            text-align: center;
        }
    }
</style>