<template>
<main>
    <header>
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <div class="logomarca">
                        <img class="logo" src="@/assets/img/master_branca.png" alt="Master peças agricolas">
                    </div>
                </div>
                <div class="col-sm-6 offset-sm-3">
                    <div class="btn-acao">
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/produtos">Produtos</a></li>
                            <li><a href="/quem-somos">Quem Somos</a></li>
                            <li><a href="/fale-conosco">Fale Conosco</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
</main>
</template>

<script>
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


    header{
        background-color: #00A859;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;
        margin-left: 3rem;
    }
    
    li a {
        color:#fff;
        font-size: 2rem;
    }

</style>