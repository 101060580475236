<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Master Peças"
      title="Master Peças"
    >
    
      <template v-slot:links>

        <sidebar-item
          :link="{
            name: 'Produtos',
            icon: 'ni ni-single-02 text-primary',
            path: '/admin/produtos',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Categorias',
            icon: 'ni ni-box-2 text-primary',
            path: '/admin/categorias',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Fale Conosco',
            icon: 'ni ni-box-2 text-primary',
            path: '/admin/fale-conosco',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Usuarios',
            icon: 'ni ni-box-2 text-primary',
            path: '/admin/usuarios',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
<style>
:root{
    font-size:100%; /*1rem = 10px */
}
</style>