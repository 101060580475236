<template>
  <main>
    <topo />
    <div class="container sobre">
      
      <div class="row">
        <div class="col-sm-10 offset-sm-1">
          <h1>Quem é a Master</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5 offset-sm-1">    
          <img src="@/assets/img/img_sobre.jpg">
        </div>
        <div class="col-sm-5">
          <p>A Master é uma empresa conceituada no mercado de peças agricolas , hoje que busca facilitar as compras de peças agricolas
            do preparo de solo até a colheita , reduzindo o custo da manutenção das maquinas, levando o maior custo 
            beneficio para o produtor e distribuindo para todo o brasil.
          </p>
          <p>Trabalhamos com uma ampla linha de peças para plantadeira , colheitadeira , pulverizadores ,plataformas , grades, pontas de pulverização ,rolamentos , filtros , correntes e correias 
            de diversas linhas e marcas . Buscando sempre ajudar os nossos clientes a resolver os seus problemas, com preço justo e qualidade.</p>
        </div>
      </div>
    </div>


    <bottom />
  </main>
</template>

<script>
import Topo from '../../layout/Site/TopoAlternativo.vue'
import Bottom from '../../layout/Site/Bottom.vue'
export default({
    setup() {
             return {
            Topo,
            Bottom
        }     
    }

})
</script>

<style scoped>
  img{
    width: 100%;
  }
  .sobre{
    margin-top:5rem;
    margin-bottom:5rem;
  }
  p{
    font-size:1.5rem;
    font-weight: bold;
  }
</style>