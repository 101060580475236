<template>

    <topo/>
    <main>
        <div class="container">
            <div class="Titulo">
                <h1 class="novidade">Novidades da Semana</h1>
            </div>
            <article class="carrossel">
                <div class="row">
                  <produtos-card
                    v-for="produto in state.destaques"
                    :key="produto.id"
                    :produto="produto"
                  />
                </div>
            </article>
            
            <div class="Titulo">
                <h1 class="novidade">Mais Acessados</h1>
            </div>   
            <article class="carrossel">
                <div class="row">
                  <produtos-card
                    v-for="produto in state.destaques"
                    :key="produto.id"
                    :produto="produto"
                  />
                </div>
            </article>

            <section class="cards">
                <div class="card">
                    <div class="image">
                    </div>
                </div>
            </section>
        </div>
    </main>
    <bottom/>
</template>
<script>
import Topo from '../../layout/Site/Topo.vue'
import Bottom from '../../layout/Site/Bottom.vue'
import ProdutosCard from '../../components/CorpoTabelaProdutoHome'
import { onMounted, reactive } from 'vue'
import services from '../../services'

export default({
    setup() {
        onMounted(() => {
            fetchDestaque()
            fetchMaisAcessados()
        })
        
        const state = reactive({
        destaques:[]
        })

        async function fetchDestaque() {
            try {
                state.isLoading = true
                const { data } = await services.produto.getDestaque({
                })
                state.destaques = data
                state.isLoading = false
                
            } catch (error) {
                handleErrors(error)
            }

            function handleErrors (error) {
            console.log(error)
            state.hasError = !!error
            }
        }

        async function fetchMaisAcessados() {
            try {
                state.isLoading = true
                const { data } = await services.produto.getMaisAcessados({
                })
                state.destaques = data
                state.isLoading = false
                
            } catch (error) {
                handleErrors(error)
            }

            function handleErrors (error) {
            console.log(error)
            state.hasError = !!error
            }
        }

        return {
            Topo,
            Bottom,
            ProdutosCard,
            state
        }     
    },
})
</script>

<style scoped>

*{
    margin: 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
    
}
main{
    background-color: red;
    background-color: #f9f9f9;
    font-family: Poppins, sans-serif; 
}
header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #f9f9f9;
    width: 98rem;
    height: 20rem;
    margin: auto;
}
header img {
   
  }


.buscar{
   
    background-color: #00A859;
    border: none;
    height: 6rem;
    width: 5rem;
    margin-left: -1rem;

    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
    

}
.bsvg{
    width: 2rem;
    align-items: center;
    margin-top: -1rem;
}
.text{
    height: 4rem;
    background-color: #f9f9f9;
    text-align: center;
    font-size: 2rem;
    color: #D4D4D4;
}
.numero{
    text-decoration: none;
    color: #00A859;

}

/*LOGO MARCA*/

.logomarca{ 
    margin-top: 5rem;
    margin-left: 1rem;
   
}



#btnPrev, #btnNext {
    position: relative;
    font-size: 5rem;
    cursor: pointer;
    height: 29rem;
    border: 0;
    width: 50px;
    border-radius: 2px;
    background: #d4d4d478;
    color: #737775;
    z-index: 1;
    
}
.img-itens{
    align-items: center;
    height: 290px;
    
}



/*FIM CARROSEL*/
.logo{
    width: 35rem;
}

/*FIM LOGO MARCA*/

/*PESQUISAR*/

.pesquisar{
    position: relative;
    width: 25rem;
    height: 5rem;
    background-color: #fff;
    border: solid 1px #D4D4D4;
    display: flex;

    margin-left: 2.5rem;
    margin-top: 10rem;

    border-radius: 1rem;
    
}
.pesquisar::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #00A859;
    transform: scaleX(0);
    transform-origin: center;
    transform-origin: left;
    transition: transform .25s;
}
.pesquisar:focus-within::before{
    transform: scale(1);
}
.pesquisar input,
.pesquisar button{
    border: none;
    outline: none;
    background-color: transparent;
    color: #8e8e8e;
}
.input{
    width: 100%;
    padding: 10px;
}
.button{
    display: grid;
    place-items: center;
    width: 50px;
    font-size: 19px;
    cursor: pointer;
    transition: color .25s;
}
.button:hover{
    color: #00A859;
}
</style>