<template>
  <div class="col-sm-3 produto" @click="handleToggle">
         
      <router-link class="link" :to="{ name: 'Produto', params: { nome_url:produto.nome_url,id: produto.id } }">
      <div class="corpo">
          <img class="img-itens" :src="produto.imagem">
          {{produto.nome}}
      </div>
       </router-link>
  </div>
</template>
<script>
import { reactive } from 'vue'

export default {
    props: {
      isOpened: { type: Boolean, default: false },
      produto: { type: Object, required: true }
    },
    setup(props) {
      const state = reactive({
        isOpen: props.isOpened,
        isClosing: !props.isOpened
      })
      async function handleToggle () {
      state.isClosing = true
      //  await wait(250)
      state.isOpen = !state.isOpen
      state.isClosing = false  
      }
      return {
        state,
        handleToggle    
      }
    },
}
</script>
<style scoped>
  img{
    width: 100%;
  }
  .corpo{
    padding: 1rem;
    border: #DEDEDE solid 2px;
    background-color:#fff;
    text-align: center;
  }
  .link{
    font-size: 1.8rem;
    font-weight: bold;
    color: #424443;
  }
  .div_nome{
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight:bold;
    color:#424443;
  }
  .produto{
    margin-bottom: 3rem;
  }
  .div_preco{
    margin-top:10px;
    color:black;
    font-size: 2rem;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
  }
  .div_botao{
    margin-top:1rem;
    text-align: center;
  }
  .btn_detalhes{
    background-color: #DEDEDE;
    font-size: 1.6rem;
    border-radius: 6px;
    border:none;
    padding: 0.8rem 1.2rem;
  }
</style>