<template>
<main>
    <topo />
      <div class="container">
        <div id="breadcrumbs">
          <ul>
            <li><a href="/">Página Inicial ></a></li>
            <li>Produtos</li>
          </ul>
        </div>
        <div class="titulo_pagina">
          <h1>Categorias</h1>
        </div>
        <div class="row produtos">
            <div class="col-sm-4 col-md-3">
              <ul>
                <categorias-card
                    v-for="categoria in state.categorias"
                    :key="categoria.id"
                    :categoria="categoria"
                    @selecionarCategoria="selecionarCategoria"
                    />
              </ul>
            </div>
            <div class="col-sm-8 col-md-9">
                <div class="row">
                    <produtos-card
                    v-for="produto in state.produtos"
                    :key="produto.id"
                    :produto="produto"
                    />
                </div>
            </div>
        </div>
      </div>
    <bottom />
</main>
</template>

<script>
import Topo from '../../layout/Site/TopoAlternativo.vue'
import Bottom from '../../layout/Site/Bottom.vue'
import ProdutosCard from '../../components/CorpoTabelaProdutoSite'
import CategoriasCard from '../../components/CorpoCategoriaSite'
import { onMounted, reactive, onErrorCaptured  } from 'vue'
import services from '../../services'

export default({
    setup() {

      const state = reactive({
        isLoading: false,
        produtos: [],
        categorias:[],
        id_categoria_selecionada: "",
        hasError: false
      })
      onErrorCaptured(handleErrors)

      onMounted(() => {
        fetchProdutos()
        fetchCategorias()
      })

      function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
      }

      async function selecionarCategoria(id_categoria_selecionada) {
        console.log('aquii');
        console.log(id_categoria_selecionada);
        try {
          state.isLoading = true
          const { data } = await services.produto.getPorCategoria({
            categoria_id: id_categoria_selecionada
          }
          )
          state.produtos = data
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }   
      }

      async function fetchProdutos () {
        try {
          state.isLoading = true
          const { data } = await services.produto.getAll({
            type: state.currentClientType
          }
          )
          state.produtos = data
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }
      async function fetchCategorias () {
        try {
          state.isLoading = true
          const { data } = await services.categoria.getAll({
            type: state.currentClientType
          }
          )
          console.log(data)
          state.categorias = data
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }

        return {
            Topo,
            Bottom,
            ProdutosCard,
            CategoriasCard,
            selecionarCategoria,
            state
        }     
    },
})
</script>

<style scoped>
  .container{
    margin-top:3rem;
  }
  .titulo_pagina{
    margin-bottom: 3rem;
  }
  h1{
    border-bottom: #00A859 solid 3px;
    display: inline;
    padding-right: 10rem;
    font-size: 2rem;
    columns: #7F8280 !important;
  }
  .produtos ul{
    border: #DEDEDE solid 1px;
    background-color: #fff;
    padding: 0;
    min-height: 20rem;
  }

#breadcrumbs{
  margin-top: 3rem;
  margin-bottom: 5rem;
}
#breadcrumbs ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#breadcrumbs li {
    float: left;
    margin-left: 0.1rem;
    list-style-type: none;
    font-size: 1.5rem;
    color:#7F8280;
}
#breadcrumbs li a {
    color:#7F8280;
}
</style>