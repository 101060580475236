import axios from 'axios'
import AuthService from './auth'
import CategoriaService from './categoria'
import ProdutoService from './produto'
import FaleConoscoService from './faleConosco'
import TipoChamadoService from './tipoChamado'
import UsuarioService from './usuario'

const API_ENVS = {
    production: '',
    development: '',
    local: 'http://back.masteragricolas.com.br/api/',

}



const httpClient = axios.create({
    baseURL: API_ENVS.local
})


export default {
    devServer: {
        proxy: 'https://localhost:8585'
    },
    auth: AuthService(httpClient),
    categoria: CategoriaService(httpClient),
    produto: ProdutoService(httpClient),
    FaleConosco: FaleConoscoService(httpClient),
    tipoChamado: TipoChamadoService(httpClient),
    usuario: UsuarioService(httpClient)
}