<template>
<modal-factory />
  <div>
  
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/admin">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/admin/usuarios" tag="primary">Usuarios</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>
          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Novo Usuario</h2>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Usuario
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Código</label>
                          <input type="text" class="form-control" v-model="state.codigo" readonly/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome </label>
                          <input type="text" class="form-control" v-model="state.nome" />
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>E-mail</label>
                          <input type="text" class="form-control" v-model="state.email" />
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="form-group">
                          <label>DDD</label>
                          <input type="text" class="form-control" v-model="state.ddd" />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Telefone </label>
                          <input type="text" class="form-control" v-model="state.telefone" />
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
                            <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Mensagem
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Mensagem </label>
                          <textarea class="form-control" v-model="state.mensagem" rows="8"></textarea>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary my-4" id="submit-button" type="submit">Save</button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancel</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>
</template>
<script>
import { reactive, onMounted } from 'vue'
import services from '../services'
import ModalFactory from '../components/ModalFactory'
import { useRouter } from 'vue-router'

export default {
  
  components: { ModalFactory },
  setup (_, { emit }) {
    const router = useRouter()
    var id_mensagem = router.currentRoute._value.params.id
    if(id_mensagem === undefined){
      id_mensagem = 0;
    }

    onMounted(() => {
      fetchProduto()
    })

    async function fetchProduto () {
      console.log('oie')
        try {
          state.isLoading = true
          const { data } = await services.FaleConosco.getById({
            id:id_mensagem
          })
          state.codigo = data.id;
          state.nome = data.nome;
          state.ddd = data.ddd;
          state.telefone = data.telefone;
          state.email = data.email;
          state.mensagem = data.mensagem;

        } catch (error) {
          handleErrors(error)
        }
    }

    function cancel(){
      router.push({ name: 'usuarios' })
    }

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      codigo: '',
      nome: '',
      ddd:'',
      telefone:'',
      email: '',
      mensagem: ''
    })

    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }

    async function handleSubmit() {

      try {
        state.isLoading = true
        if(state.codigo.value){
          const { errors } = await services.categoria.update({
            id: state.codigo.value,
            nome: state.nome.value,
            status: state.status.value
          })
          if (!errors) {
            router.push({ name: 'categorias' })
            state.isLoading = false
            return
          }
        }else{
          console.log('sem codigo');
           const { errors }= await services.categoria.save({
            id: state.codigo.value,
            nome: state.nome.value,
            status: state.status.value
          })
          if (!errors) {
            router.push({ name: 'categorias' })
            state.isLoading = false
            return
          }
        }
        state.isLoading = false
      } catch (error) {
        handleErrors(error)
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
  return {
    state,
    handleSubmit,
    emit,
    cancel
  }
  }
}
</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>