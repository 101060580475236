<template>
<modal-factory />
  <div>
  
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/admin">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/admin/categorias" tag="primary">Categorias</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>
          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Nova Categoria</h2>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Categoria
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Código</label>
                          <input type="text" class="form-control" v-model="state.codigo.value" readonly/>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Status</label>
                          <select class="form-control" v-model="state.status.value">
                            <option value="ATIVO">Ativo</option>
                            <option value="INATIVO">Inativo</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome </label>
                          <input type="text" class="form-control" v-model="state.nome.value" />
                          <span v-if="!!state.nome.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nome.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary my-4" id="submit-button" type="submit">Save</button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancel</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>
</template>
<script>
import { reactive, onMounted } from 'vue'
import services from '../services'
import { useField } from "vee-validate";
import { validateEmptyAndLength3 } from '../utils/validators'
import ModalFactory from '../components/ModalFactory'
import { useRouter } from 'vue-router'

export default {
  
  components: { ModalFactory },
  setup (_, { emit }) {
    const router = useRouter()
    var id_categoria = router.currentRoute._value.params.id
    if(id_categoria === undefined){
      id_categoria = 0;
    }


    function cancel(){
      router.push({ name: 'categorias' })
    }
    const {
      value: codigoValue,
      errorMessage: codigoErrorMessage
    } = useField('codigo', validateEmptyAndLength3)

    const {
      value: nomeValue,
      errorMessage: nomeErrorMessage
    } = useField('nome', validateEmptyAndLength3)

    const {
      value: statusValue,
      errorMessage: statusErrorMessage
    } = useField('status', validateEmptyAndLength3)


    const state = reactive({
      hasErrors: false,
      isLoading: false,
      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage
      },
      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage
      },
      status: {
        value: statusValue,
        errorMessage: statusErrorMessage
      }
    })

    onMounted(() => {
      fetchCategoria()
    })

    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }
    async function fetchCategoria () {
      if(id_categoria != 0){
        try {
          state.isLoading = true
          const { data } = await services.categoria.getById({
            id:id_categoria
          })
          state.codigo.value = data.id
          state.nome.value = data.nome
          state.status.value = data.status

        } catch (error) {
          handleErrors(error)
        }
      }
    }
    async function handleSubmit() {

      try {
        state.isLoading = true
        if(state.codigo.value){
          const { errors } = await services.categoria.update({
            id: state.codigo.value,
            nome: state.nome.value,
            status: state.status.value
          })
          if (!errors) {
            router.push({ name: 'categorias' })
            state.isLoading = false
            return
          }
        }else{
          console.log('sem codigo');
           const { errors }= await services.categoria.save({
            id: state.codigo.value,
            nome: state.nome.value,
            status: state.status.value
          })
          if (!errors) {
            router.push({ name: 'categorias' })
            state.isLoading = false
            return
          }
        }
        state.isLoading = false
      } catch (error) {
        handleErrors(error)
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
  return {
    state,
    handleSubmit,
    emit,
    cancel
  }
  }
}
</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>