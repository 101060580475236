<template>
  <tr @click="handleToggle">
    <th scope="row">
      <div class="media align-items-center">
        <div class="media-body">
          <span class="name mb-0 text-sm"></span>
          {{ produto.id }}
        </div>
      </div>
    </th>
    <td>
     {{ produto.nome }}
    </td>
    <td>
      {{ produto.nome_categoria }}
    </td>
    <td>
      {{ produto.status }}
    </td>
    <td class="text-right">
        <router-link :to="{ name: 'AlterarProduto', params: { id: produto.id } }">
            <button class="btn btn-primary" >Ver / Alterar</button>
        </router-link>
        <button class="btn btn-danger" @click="$emit('deletarProduto',produto.id)">Deletar</button>
    </td>
  </tr>
</template>
<script>
import { reactive } from 'vue'

export default {
    props: {
      isOpened: { type: Boolean, default: false },
      produto: { type: Object, required: true }
    },
    setup(props) {
      const state = reactive({
        isOpen: props.isOpened,
        isClosing: !props.isOpened
      })
      async function handleToggle () {
      state.isClosing = true
      //  await wait(250)
      state.isOpen = !state.isOpen
      state.isClosing = false  
      }
      return {
        state,
        handleToggle    
      }
    },
}
</script>
