<template>
<modal-factory />
  <div>
  
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/admin">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/admin/usuarios" tag="primary">Usuarios</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>
          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Novo Usuario</h2>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Usuario
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Código</label>
                          <input type="text" class="form-control" v-model="state.codigo.value" readonly/>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Status</label>
                          <select class="form-control" v-model="state.status.value">
                            <option value="ATIVO">Ativo</option>
                            <option value="INATIVO">Inativo</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome </label>
                          <input type="text" class="form-control" v-model="state.nome.value" />
                          <span v-if="!!state.nome.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nome.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>E-mail</label>
                          <input type="text" class="form-control" v-model="state.email.value" />
                          <span v-if="!!state.email.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.email.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div> 
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Senha </label>
                          <input type="password" class="form-control" v-model="state.senha.value" />
                          <span v-if="!!state.senha.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.senha.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary my-4" id="submit-button" type="submit">Save</button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancel</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>
</template>
<script>
import { reactive, onMounted } from 'vue'
import services from '../services'
import { useField } from "vee-validate";
import { validateEmptyAndLength3 } from '../utils/validators'
import ModalFactory from '../components/ModalFactory'
import { useRouter } from 'vue-router'

export default {
  
  components: { ModalFactory },
  setup (_, { emit }) {
    const router = useRouter()
    var id_usuario = router.currentRoute._value.params.id
    if(id_usuario === undefined){
      id_usuario = 0;
    }

    function cancel(){
      router.push({ name: 'usuarios' })
    }
    const {
      value: codigoValue,
      errorMessage: codigoErrorMessage
    } = useField('codigo', validateEmptyAndLength3)

    const {
      value: nomeValue,
      errorMessage: nomeErrorMessage
    } = useField('nome', validateEmptyAndLength3)

    const {
      value: emailValue,
      errorMessage: emailErrorMessage
    } = useField('email', validateEmptyAndLength3)

    const {
      value: senhaValue,
      errorMessage: senhaErrorMessage
    } = useField('senha', validateEmptyAndLength3)

    const {
      value: statusValue,
      errorMessage: statusErrorMessage
    } = useField('status', validateEmptyAndLength3)

    onMounted(() => {
      fetchUsuario()
    })

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage
      },
      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage
      },
      email: {
        value: emailValue,
        errorMessage: emailErrorMessage
      },
      senha: {
        value: senhaValue,
        errorMessage: senhaErrorMessage
      },
      status: {
        value: statusValue,
        errorMessage: statusErrorMessage
      }
    })

    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }

    async function fetchUsuario () {
      if(id_usuario != 0){
        try {
          state.isLoading = true
          const { data } = await services.usuario.getById({
            id:id_usuario
          })
          state.codigo.value = data.id
          state.nome.value = data.nome
          state.email.value = data.email
          state.status.value = data.status

        } catch (error) {
          handleErrors(error)
        }
      }
    }



    async function handleSubmit() {

      try {
        state.isLoading = true
        if(state.codigo.value){
          const { errors } = await services.usuario.update({
            id: state.codigo.value,
            nome: state.nome.value,
            email: state.email.value,
            senha: state.senha.value,
            status: state.status.value
          })
          if (!errors) {
            router.push({ name: 'usuarios' })
            state.isLoading = false
            return
          }
        }else{
          console.log('sem codigo');
           const { errors }= await services.usuario.save({
            nome: state.nome.value,
            email: state.email.value,
            senha: state.senha.value,
            status: state.status.value
          })
          if (!errors) {
            router.push({ name: 'usuarios' })
            state.isLoading = false
            return
          }
        }
        state.isLoading = false
      } catch (error) {
        handleErrors(error)
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    return {
      state,
      handleSubmit,
      emit,
      cancel
    }
  }
}
</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>