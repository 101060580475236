<template>
  <div class="col-sm-6 col-md-4 produto" @click="handleToggle">
    <div class="corpo">
        <img class="img-itens" :src="produto.imagem">

        <div class="div_nome">
          {{produto.nome}}
        </div>
        <div class="div_preco">
          R$ {{produto.preco}}
        </div>

        <div class="div_botao">
          <router-link :to="{ name: 'Produto', params: { nome_url:produto.nome_url,id: produto.id } }">
            <button class="btn_detalhes">Ver Detalhes</button>
          </router-link>
        </div>


    </div>

  </div>
</template>
<script>
import { reactive } from 'vue'

export default {
    props: {
      isOpened: { type: Boolean, default: false },
      produto: { type: Object, required: true }
    },
    setup(props) {
      const state = reactive({
        isOpen: props.isOpened,
        isClosing: !props.isOpened
      })
      async function handleToggle () {
      state.isClosing = true
      //  await wait(250)
      state.isOpen = !state.isOpen
      state.isClosing = false  
      }
      return {
        state,
        handleToggle    
      }
    },
}
</script>
<style scoped>
  img{
    width: 100%;
  }
  .corpo{
    padding: 1rem;
    border: #DEDEDE solid 2px;
    background-color:#fff;
  }
  .div_nome{
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight:bold;
    color:#424443;
  }
  .produto{
    margin-bottom: 3rem;
  }
  .div_preco{
    margin-top:10px;
    color:black;
    font-size: 2rem;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
  }
  .div_botao{
    margin-top:1rem;
    text-align: center;
  }
  .btn_detalhes{
    background-color: #DEDEDE;
    font-size: 1.6rem;
    border-radius: 6px;
    border:none;
    padding: 0.8rem 1.2rem;
  }
</style>