<template>
<main>
    <header>
        <div class="div_logo_mobile d-block d-sm-none">
            <a href="/">
            <img alt="Apracam Logo" class="logo_mobile" src="@/assets/img/masteerlogo.svg" />
            </a>
        </div>



        <nav class="navbar navbar-expand-lg navbar-light d-block d-sm-none">       
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <div class="my-2 my-lg-0">
                <ul class="navbar-nav">
                    <li class="nav-item menu-item">
                    <a class="link-header" href="/">Home</a>
                    </li>
                    <li class="nav-item menu-item">
                    <a class="link-header" href="/produtos">Produtos</a>
                    </li>
                    <li class="nav-item menu-item">
                    <a class="link-header" href="/quem-somos">Quem Somos</a>
                    </li>
                    <li class="nav-item menu-item">
                    <a class="link-header" href="/fale-conosco">Fale Conosco</a>
                    </li>          
                </ul>
            </div>
            </div>
        </nav>
        <div class="container d-none d-sm-block">
          <div class="row">
            <div class="col-sm-6 col-md-4">
              <div class="logomarca  d-none d-sm-block">
                <img class="logo" src="@/assets/img/masteerlogo.svg" alt="Master peças agricolas">
              </div>
            </div>
            <div class="col-sm-5 col-md-4">
                <form class="pesquisar ">
                    <input type="text" placeholder="Digite o que deseja procurar..." class="input">
                    <button type="submit" class="button">
                        <i class="fas fa-search"></i>
                    </button>  
                </form>
            </div>
            <div class="col-md-4 d-md-none d-sm-none  d-lg-block">
                <div class="btn-acao  ">
                    <a class="aten" href="#">Atendimento</a>
                </div>
            </div>
          </div>
        </div>        
    </header>
    
        <nav class="dp-menu d-none d-sm-block">
            <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/produtos">Produtos</a></li>
                <li><a href="/quem-somos">Quem Somos</a> </li>
                <li><a href="/fale-conosco">Fale Conosco</a></li>
            </ul> 
       </nav>
        <div class="box d-none d-sm-block">
            <img class="imag" src="@/assets/img/backgroud.png">
        </div>
        <div class="container d-none d-sm-block">
          <div class="container1 ">
            <div class="row">
                <div class="col-sm-4">
                    <div class="consultor">
                        <img src="@/assets/img/consultor.png">
                        <h1>Quer tirar dúvidas?</h1>
                        <p>Fale com um consultor</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="whats">
                        <img src="@/assets/img/whats.png">
                        <h1>Whatsapp Exclusivo</h1>
                        <p>para clientes Master</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="feedback">
                        <img src="@/assets/img/feedback.png">
                        <h1>Nossos Feedback</h1>
                        <p>veja o que estão falando</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
</main>
</template>

<script>
</script>
<style>
:root{
    font-size: 62.5%; /*1rem = 10px */
}
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


  .navbar-collapse{
    position: static;
  }
.buscar{
    background-color: #00A859;
    border: none;
    height: 6rem;
    width: 5rem;
    margin-left: -1rem;

    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
}
.bsvg{
    width: 2rem;
    align-items: center;
    margin-top: -1rem;
}
.text{
    height: 4rem;
    background-color: #f9f9f9;
    text-align: center;
    font-size: 2rem;
    color: #D4D4D4;
}
.numero{
    text-decoration: none;
    color: #00A859;
}

/*LOGO MARCA*/

.logomarca{ 
    margin-top: 5rem;
    margin-left: 1rem;
}

.logo{
    width: 100%;
}

/*FIM LOGO MARCA*/

/*PESQUISAR*/

.pesquisar{
    position: relative;
    width: 25rem;
    height: 5rem;
    background-color: #fff;
    border: solid 1px #D4D4D4;
    display: flex;

    margin-left: 2.5rem;
    margin-top: 10rem;

    border-radius: 1rem;
    
}
.pesquisar::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #00A859;
    transform: scaleX(0);
    transform-origin: center;
    transform-origin: left;
    transition: transform .25s;
}
.pesquisar:focus-within::before{
    transform: scale(1);
}
.pesquisar input,
.pesquisar button{
    border: none;
    outline: none;
    background-color: transparent;
    color: #8e8e8e;
}
.input{
    width: 100%;
    padding: 10px;
}
.button{
    display: grid;
    place-items: center;
    width: 50px;
    font-size: 19px;
    cursor: pointer;
    transition: color .25s;
}
.button:hover{
    color: #00A859;
}

/*div-container1*/
.container1{
    background-color: #0B7046;
    width: 100%;
    margin: auto;
    position: relative;
    top: -15rem;
    border-radius: 2rem;
}
.consultor{
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.consultor:hover{
    background-color: #00A859;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
}   
.whats{
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 5rem;
}
.whats:hover{
    background-color: #00A859;
}  
.feedback{
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 4.7rem;
}
.feedback:hover{
    background-color: #00A859;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
}

/*BTN-ACAO*/

.btn-acao{
    padding-top: 11rem;   
}
.aten{
    font-size: 2rem;
    padding: 1rem;
    color: #8e8e8e;
}
.aten:hover{
    background-color: #00A859;
    color: white;
    font-size: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    transition: 0.7s;
}
.login{
    font-size: 2rem;
    padding: 1rem;
    
}
.login:hover{
    background-color: #00A859;
    color: white;
    font-size: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    transition: 0.7s;
}

/*FIM BTN-ACAO*/

h1{
    font-size: 2rem;
    color: white;
}
p{
    font-size: 1.5rem;
    color: white;
}



/*NAV*/
.dp-menu{
    text-align: center;
    background-color: #f9f9f9; 
    height: 10rem; 
}
.dp-menu ul{
    width: 98rem;
    margin: auto;  
    padding-top: 3rem;  

}
.dp-menu ul li{
   display: inline;
   position: relative;
   padding-left: 3rem;   
}
.dp-menu ul li a{
    color: #737775;
    font-size: 2.5rem;
    font-family: Poppins, sans-serif;
    text-decoration: none;
    padding: 1rem;
    transition: background .4s;   
}
.dp-menu ul li a:hover{
    background-color: #00A859;
    color: white;
    border-radius: .5rem;
}

.dp-menu ul li:hover ul{
    display: block;     
}
.dp-menu ul ul{
    width: 15rem;
}
.dp-menu ul ul li a{
    display: block;
}
.dp-menu ul ul li a:hover{
    background-color: #0B7046;
}


.imag{
    width: 100%;
    height: 70rem;
}

.div_logo_mobile{
    text-align: center;
    width: 100%;
}
.logo_mobile{
    width:80%;
}
#navbarSupportedContent{
    margin-top: 5rem;
}
.menu-item{
    text-align: center;
    color: #00A859;
    margin-bottom:1rem;
}

.menu-item a{
    color: #00A859;
}



@media (min-width: 577px) and (max-width:768px) { 
    .pesquisar{
        margin-top: 5rem;
        width: 100%;
    }
    .dp-menu ul{
        width: 100%;
    } 
    .dp-menu ul li{
        padding-left:2rem;
    }
    .dp-menu ul li a{
        font-size: 1.5rem;
    }
    .imag{
        height: 40rem;
    }
    h1{
        font-size:1.5rem
    }
    .consultor{
        padding-bottom:2rem;
    }
    .consultor img{
        width: 60px;
        margin-bottom: 13px;
    }
    .whats{
        padding-bottom:2rem;
    }   
    .whats img{
        width: 50px;
        margin-bottom: 8px;
    }
    .feedback {
        padding-bottom: 2rem;
    }
    .feedback img{
        width: 50px;
        margin-bottom: 38px;
    }

}

@media (min-width: 769px) and (max-width:990px) { 
    .logomarca{
    }

    .pesquisar{
        margin-top: 5rem;
        width: 100%;
    }
    .dp-menu ul{
        width: 100%;
    } 
    .dp-menu ul li{
        padding-left:2rem;
    }
    .dp-menu ul li a{
        font-size: 2rem;
    }
    .imag{
        height: 55rem;
    }
}



</style>